import type { LinksFunction } from '@remix-run/node';
import { Link, Outlet } from '@remix-run/react';
import { useTypedRouteLoaderData as useRouteLoaderData } from 'remix-typedjson';
import type { loader } from '~/root';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';

import { PlannedMaintenanceBanner } from '~/components/.shared/header/banners/PlannedMaintenanceBanner';
import { LogoCW } from '~/components/.ui/LogoCW';
import { TwoCol } from '~/components/.ui/layout/twoCol/TwoCol';
import { ZapierAnnouncement } from '~/components/.ui/marketing/ZapierAccouncement';
import type { ScriptType, ScriptsFunction } from '~/components/root/scripts/ExternalScripts.client';

import authStyles from '~/styles/auth.css';
import cookieBotStyles from '~/styles/cookieBot.css';

export default function AuthParent() {
  const loaderData = useRouteLoaderData<typeof loader>('root');

  const whiteLabelLogo = loaderData?.loginLogo;

  return (
    <>
      <PlannedMaintenanceBanner />
      <TwoCol>
        <TwoCol.Column className="h-full md:overflow-y-auto flex flex-col shrink-0 grow md:shrink-1 justify-between after:block">
          {whiteLabelLogo ?
          <div className="custom-logo-container">
              <img className="custom-logo" src={whiteLabelLogo} alt="custom login logo" />
            </div> :

          <div className="logo">
              <Link aria-label="Content Workflow" to="/">
                <LogoCW />
              </Link>
            </div>}

          <div className="auth-body">
            <div className="auth-content">
              <Outlet />
            </div>
          </div>
        </TwoCol.Column>
        <TwoCol.Column className="bg-neutral-98 md:overflow-y-auto border-t md:border-t-0 md:border-l border-solid border-neutral-90">
          <ZapierAnnouncement />
        </TwoCol.Column>
      </TwoCol>
    </>);

}

export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: authStyles },
  { rel: 'stylesheet', href: cookieBotStyles }];

};

const scripts: ScriptsFunction = () => {
  const loaderData = useRouteLoaderData<typeof loader>('root');

  /**
   * If a user already has processed consent, we need to load the script differently or else cookie bot errors.
   * See `public/_remix/scripts/cookiebot.js` for more on the cookie bot workaround.
   */
  const cookieConsentScripts: ScriptType[] = loaderData?.consent ?
  [
  {
    src: 'https://consent.cookiebot.com/uc.js?cbid=e4220961-5399-44db-91f1-bd3f5364ee6d',
    id: 'Cookiebot',
    location: 'body'
  }] :

  [
  {
    src: 'https://consent.cookiebot.com/uc.js',
    id: 'Cookiebot',
    location: 'body'
  },
  {
    src: getSrcFromCDN('/scripts/cookiebot.js'),
    id: 'cookie-consent-trigger',
    location: 'body'
  }];


  return [
  ...cookieConsentScripts,
  {
    src: 'https://js.hcaptcha.com/1/api.js?render=explicit&recaptchacompat=off',
    id: 'hcaptcha',
    location: 'body'
  }];

};

export const handle = { scripts };