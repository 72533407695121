import { Badge } from '@gathercontent/ui';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';

export function ZapierAnnouncement() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center md:w-8/12">
        <Badge badgeColour="purple" className="hidden mb-3 leading-none md:inline-flex">
          LATEST ANNOUNCEMENT
        </Badge>
        <h2 className="my-4 text-base md:text-2xl md:mb-6 md:mt-0">Our Zapier integration is live!</h2>
        <img
          className="object-contain hidden w-full h-auto m-auto md:block"
          style={{ maxWidth: '720px' }}
          src={getSrcFromCDN('/marketing/zapier.svg')}
          alt={`Connect $Content Workflow with over 4,000 apps`}
        />
        <p className="hidden mt-2 mb-6 md:block">
          Connect Content Workflow with thousands of apps to provide powerful automation with the tools you use every
          day. Available on all plans.
        </p>
        <a
          href="https://gathercontent.com/lp/zapier"
          className="inline-block mb-6 button-base button-secondary button-base-md hover:no-underline"
        >
          Find out more
        </a>

        <a href="https://gathercontent.com/whats-new" className="hidden text-sm md:block">
          See what else is new
        </a>
      </div>
    </div>
  );
}
