import { ReactNode } from 'react';

import { TwoColColumn } from './TwoColColumn';

function TwoCol({ children }: { children: ReactNode }) {
  return <div className="flex flex-col min-h-screen md:h-screen md:flex-row flex-shrink-0">{children}</div>;
}

TwoCol.Column = TwoColColumn;

export { TwoCol };
